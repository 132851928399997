import { idToUrn } from '@app/utils/core/urn-helper';
import { ClientMetaFunction, mergeMeta } from '@app/utils/remix/play-now-meta';
import { leadAndDescription } from '@play-now/core/models/Media';
import { scaleImageSource } from '@play-now/core/utils/image-helper';
import { parseIntOrUndefined } from '@play-now/core/utils/number-helper';
import { loadVideoDetail } from '@play-now/video/api/load-video-detail';
import { aspectRatioMap } from '@play-now/video/components/Image/PlayImage';
import { metaImageWidth } from '@play-now/video/components/Meta/Meta.config';
import { getRemixMeta } from '@play-now/video/components/Meta/PlayMetaData';
import { getMediaSchemas } from '@play-now/video/components/PageMetaData/JsonLd';
import { useInitialData } from '@play-now/video/config/VideoAppContext';
import { notFoundPath } from '@play-now/video/pages/Error/PageNotFound.route';
import { getVideoPageTitle, VideoDetailPage } from '@play-now/video/pages/VideoDetail/VideoDetailPage';
import { videoDetailPath } from '@play-now/video/pages/VideoDetail/VideoDetailPage.route';
import { json, LoaderFunctionArgs, redirect } from '@remix-run/node';
import { useLoaderData } from '@remix-run/react';

import { PlayLayout } from './PlayLayout';
import { getRedirectRoute, setupPlayUtils } from './remix-route-helper';

export const loader = async (args: LoaderFunctionArgs) => {
  const {
    axios,
    query,
    config,
    request: { url },
  } = setupPlayUtils(args);

  // Redirect to 404 page if no id or urn is provided
  if (!query.urn?.length && !query.id?.length) {
    return redirect(config.baseUrl + notFoundPath());
  }

  const startTime = parseIntOrUndefined(query.startTime?.toString());
  const castedQuery = Object.fromEntries(new URL(url).searchParams);
  // Redirect to video detail page by urn if only id is provided
  if (query.id?.length && !query.urn?.length) {
    const urn = idToUrn(query.id, config.businessUnit, 'video');
    return redirect(config.baseUrl + videoDetailPath({ urn }, { startTime, ...castedQuery }));
  }

  let videoMeta;
  const videoDetail = await loadVideoDetail(axios, query.urn);
  if (videoDetail) {
    // Redirect to video detail page with full path if needed
    const redirectResponse = getRedirectRoute({
      fullPath: config.baseUrl + videoDetailPath(videoDetail, { startTime, ...castedQuery }),
      url,
    });
    if (redirectResponse) return redirectResponse;

    //  Get the meta data
    videoMeta = getRemixMeta({
      config,
      title: getVideoPageTitle(videoDetail),
      description: leadAndDescription(videoDetail),
      image: scaleImageSource(videoDetail.imageUrl, metaImageWidth, 'jpg', config.modernImagesBaseUrl),
      imageWidth: metaImageWidth,
      imageHeight: metaImageWidth / aspectRatioMap['16/9'],
      type: 'video.other',
      allowIndexing: videoDetail.allowIndexing,
      'script:ld+json': getMediaSchemas(videoDetail, config.baseUrl, config.language, config.modernImagesBaseUrl),
    });
  }

  return json({ videoDetail, meta: videoMeta, isInitialLocation: true });
};

export const meta: ClientMetaFunction<typeof loader> = ({ data, matches }) => mergeMeta({ data, matches });

// Exporting an empty client loader enables instant client-side navigation to the video detail page.
// This works because the VideoDetailPage can load its own data when the loader returns nothing.
export const clientLoader = async () => ({ videoDetail: undefined, isInitialLocation: false });

const VideoDetailPageRoute = () => {
  const { videoDetail, isInitialLocation } = useLoaderData<typeof loader>();
  const initialData = useInitialData();
  return (
    <PlayLayout pageId='VIDEO'>
      <VideoDetailPage initialData={{ ...initialData, videoDetail }} isInitialLocation={isInitialLocation} />
    </PlayLayout>
  );
};

export default VideoDetailPageRoute;
